import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  en: {
    mainMenu: {
      aboutus:'ABOUT US',
      service:'SERVICE',
      case:'PROJECT',
    },
    ourService: {
      title: 'Our Service',
      // desc: 'We offer one-stop solution from project planning to post-campaign services.'
    },
    ourProject: {
      title: 'Our Project',
      // desc: 'Our team has been running Kickstarter campaigns since 2013 and enabled many creators to publish a successful crowdfunding project.'
    },
    aboutUs: {
      title: 'About Us',
      description1: 'Welcome to Twowgo, where innovation meets creativity! We are a platform dedicated to discovering, sharing, and promoting exciting designs and crowdfunding projects to the world.',
      description2: ' We are passionate seekers of innovative ideas and advocates of crowdfunding. We believe that every product creator and designer deserves a chance to turn their unique vision into reality, creating lifestyles and cultures that align with their vision. With our team\'s extensive experience in running successful Kickstarter campaigns since 2013, we have empowered numerous creators to publish their crowdfunding projects. We offer "One-Stop Solution" that encompasses strategy planning, storytelling, graphic design, and all kinds of marketing activities to drive explosive traffic to the projects, covering everything and ensuring creators have all the support they need for a thriving campaign.',
      description3: 'Join us on this exhilarating journey of discovering the most innovative and game-changing projects. Together, let\'s bring creative dreams to life and shape a brighter future!',
      contactUs: 'Contact us',
    },
    newsletter: {
      title: 'Newsletter',
      description: 'Sign up for our newsletter to get updates on the latest and most innovative projects. Your privacy is paramount, and you can unsubscribe at any time!',
      btn: 'SIGN UP',
    },
    footer: {
      aboutUs: 'About Us',
      policy: 'Policy',
      faq: 'FAQ',
      customerService: 'Customer Service'
    }
  },
  tw: {
    mainMenu: {
      aboutus:'關於我們',
      service:'服務項目',
      case:'成功案例',
    },
    ourService: {
      title: '服務項目',
      // desc: '提供一站式服務方案協助台灣品牌商成功執行海外群眾募資活動'
    },
    ourProject: {
      title: '成功案例',
      // desc: '我們團隊從 2013 年起即以自有品牌發表多個 Kickstarter 專案，並自 2019 年起開始協助國內品牌商規劃與執行海外群眾募資活動，至今已累積數十多個成功案例、總募資金額達數百萬美元'
    },
    aboutUs: {
      title: '關於我們',
      description1: 'Twowgo是一個充滿創意的平台，目標是發現、分享和推廣創新與實用兼具的設計及群眾募 資專案，讓創意與世界相遇!我們對於尋找創意理念充滿熱情、也深信能透過群眾募資的力 量讓每個商品創作者和設計師都有機會將獨特的願景轉化為現實。',
      description2: '自2013年以來我們團隊已成功發表數十項Kickstarter專案，幫助無數創作者實現群眾募資的 夢想。我們提供「一站式解決方案」，從初期策略規劃到後期募資結束後之服務，包括商業 策略與市場定位、故事編寫、視覺設計、廣告執行等，全方位支持創作者成功打造國際化的 群眾募資活動，確保將產品呈現給適合的受眾、讓創意理念得以實現。',
      description3: '讓我們一同踏上這令人振奮的旅程，發掘最具創新和有潛力改變世界的商品與設計，共同實現創意夢想、創造更美好的未來!',
      contactUs: '聯絡我們',
    },
    newsletter: {
      title: '訂閱電子報',
      description: '訂閱我們的電子報以獲取最新、最具創意的產品與來自全世界的群眾募資專案消息。我們極度重視您的隱私，您可以隨時取消訂閱!',
      btn: '訂閱',
    },
    footer: {
      aboutUs: '關於我們',
      policy: '政策與條款',
      faq: '常見問題',
      customerService: '客服中心'
    }
  }
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en', // set locale
  messages, // set locale messages
})

export default i18n