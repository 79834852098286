import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from '@/i18n'
import vuetify from './plugins/vuetify';
import VueParticles from "vue-particles";
import CoolLightBox from 'vue-cool-lightbox';
import VueScrollactive from 'vue-scrollactive';
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import './assets/scss/main.scss'
import VueCookie from 'vue-cookie'
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";


const firebaseConfig = {
    apiKey: "AIzaSyCDPFVsWGA6O4Me83MCKE0t5IPAs73pWU4",
    authDomain: "twowgo-epaper.firebaseapp.com",
    databaseURL: "https://twowgo-epaper-default-rtdb.firebaseio.com",
    projectId: "twowgo-epaper",
    storageBucket: "twowgo-epaper.appspot.com",
    messagingSenderId: "707361376841",
    appId: "1:707361376841:web:3b8ccaedb6defbee380d98",
    measurementId: "G-JKG9S8EF73"
};
const app = initializeApp(firebaseConfig);
const db = getDatabase(app)
Vue.prototype.$db = db

Vue.config.productionTip = false;
Vue.use(VueParticles);
Vue.use(CoolLightBox);
Vue.use(VueScrollactive);
Vue.use(VueCookie);

new Vue({
    i18n,
    router,
    vuetify,
    VueCookie,
    render: h => h(App)
}).$mount('#app')